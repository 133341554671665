import React, { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import {
  GlobalProvider,
  Page404,
  Page500,
  Home,
  Medico,
  Saude,
  Digital,
  // Login,
  // Logout,
  // Dashboard,
  Loading,
  Privacidade,
  Bio,
} from './config/imports';
import * as SEO from './config/seo';
import './assets/css/custom.scss';
import './assets/css/root.css';
import './assets/css/color.css';
import './assets/css/svg.css';
import './assets/css/style.css';

export default function App() {
  useEffect(() => {
    SEO.defineMeta();
    SEO.defineSchema();
  }, []);

  return (
    <GlobalProvider>
      <Routes>

        <Route
          path='/bio'
          element={<Bio />}
        />

        <Route
          path='/loading'
          element={<Loading />}
        />

        {/* <Route
          path='/login'
          element={<Login />}
        />
        <Route
          path='/logout'
          element={<Logout />}
        />
        <Route
          path='/app'
          element={<Dashboard />}
        /> */}
        <Route
          path='/privacidade'
          element={<Privacidade />}
        />
        <Route
          path='/direito-digital'
          element={<Digital />}
        />

        <Route
          path='/direito-medico'
          element={<Medico />}
        />

        <Route
          path='/direito-saude'
          element={<Saude />}
        />

        <Route
          exact
          path='/'
          element={<Home />}
        />
        <Route
          exact
          path='/404'
          name='Page 404'
          element={<Page404 />}
        />
        <Route
          exact
          path='/500'
          name='Page 500'
          element={<Page500 />}
        />
        <Route
          path='*'
          element={<Page404 />}
        />
      </Routes>
    </GlobalProvider>
  );
}
